/* 開発中の仮置きCSS　
 * 消すか、 style.css に移動する
 */

.abe--keep {
    display: block;
    position: fixed;
    z-index: 9999;
}

.abe--keep-list {
    display: flex;
}

.abe--keep-list>div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.abe--keep-ss {
    width: 100px;
}

.simulationModel.zoom {
    zoom: 1.25;
}


/*
.simulationBlock.loading--preview canvas {
    opacity: 0.2;
}
*/
